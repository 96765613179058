import React from "react"


export default function SplitImageFeatures(props) {
    return (
        <div className="bg-white">
            <section aria-labelledby="features-heading" className="relative">
                <div className="overflow-hidden lg:absolute lg:w-1/2 lg:h-full lg:pr-4 xl:pr-16">
                    {!props.children &&
                        <img
                            src="https://tailwindui.com/img/ecommerce-images/confirmation-page-01-hero.jpg"
                            alt="Black leather journal with silver steel disc binding resting on wooden shelf with machined steel pen."
                            className="h-full w-full object-center object-cover lg:h-full lg:w-full"
                        />
                    }
                    {props.children}
                </div>

                <div className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:pb-32 sm:px-6 lg:max-w-7xl lg:pt-32 lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
                    <div className="lg:col-start-2">
                        <h2 id="features-heading" className="font-medium text-gray-500">
                            {props.title}
                        </h2>
                        <p className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight">{props.subtitle}</p>
                        <p className="mt-4 text-gray-500">
                            {props.description}
                        </p>

                        <dl className="mt-10 grid grid-cols-1 gap-y-10 gap-x-8 text-sm sm:grid-cols-2">
                            {props.features.map((feature) => (
                                <div key={feature.name}>
                                    <dt className="font-medium text-gray-900">{feature.name}</dt>
                                    <dd className="mt-2 text-gray-500">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </section>
        </div>
    )
}

SplitImageFeatures.defaultProps = {
    features: [
        {
            name: 'Durable',
            description: 'The leather cover and machined steel disc binding stand up to daily use for years to come.',
        },
        {
            name: 'Refillable',
            description: 'Buy it once and refill as often as you need. Subscribe and save on routine refills.',
        },
        {
            name: 'Thoughtfully designed',
            description:
                'The comfortable disc binding allows you to quickly rearrange pages or combine lined, graph, and blank refills.',
        },
        {
            name: 'Locally made',
            description: 'Responsibly and sustainably made real close to wherever you are, somehow.'
        },
    ]
}