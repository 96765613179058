import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "/src/components/layout"
import TechnicalSpecs from "/src/components/products/technicalspecsimagegrid"
import SplitImageFeatures from "/src/components/products/splitimage"
import CallToAction from "../../components/calltoaction"

const features = [
    {
        name: 'Powerful base-mounted agitator',
        description: 'The 250 Watt agitator mixes any milk replacer lump-free in seconds. In addition, the agitator supports the heater through short mixing intervals. Important: these intervals are adapted to the milk quantity. Its location in the base guarantees maximum working safety and means nothing gets in the way during tank cleaning.'
    },
    {
        name: 'Gentle heater',
        description: 'The MilkTaxi works with a surface heater over the entire base area. Unlike with spiral heaters, this prevents the occurrence of "hotspots" where milk can burn and stick. This means that the milk heats up quickly and its quality is still maintained. The display keeps you informed about the current temperature of your feed.'
    },
    {
        name: 'Simple dispensing',
        description: 'With 9 freely programmable dosing levels you can dispense almost any feed quantity – without any annoying dripping. In addition, the handle has been ergonomically adapted and is nice and light. Thanks to the integrated battery, you are independent of the power supply near the calves. This leaves you completely flexible in your choice of positioning of individual igloos and allows you to feed calves in different places.',
    },
    {
        name: 'EL-AN electrical drive',
        description: "You can move all models forwards and backwards at two different speeds using the stepless electrical drive. This not only makes calf feeding fun, but it also goes easy on your back."
    },
    {
        name: 'Stable 4-wheel undercarriage',
        description: "The MilkTaxi is extremely stable on 4 wheels with a low centre of gravity. It does not topple over even when moved quickly, over uneven terrain and fully loaded. Unevenness or small thresholds are not a problem thanks to the 40 cm front wheels and the generous ground clearance. The 260 l model is also available as a single-axle trailer as an alternative."
    },
    {
        name: 'SmartMix calculator for CMR',
        description: "From now on, you only need to specify the desired milk replacer (CMR) concentration (percentage of dry matter content) and your scoop size once. You then fill the MilkTaxi up to the required drinking quantity. Smart-Mix adds the necessary weight of the CMR automatically and tells you how many scoops of the CMR you need. Even the solubility of the CMR is taken into account, because 20 kg CMR dissolved in 100 l water is less than 120 l milk (namely 114 l). The MilkTaxi 4.0 automatically corrects this error."
    },
    {
        name: 'Exact filling level display',
        description: "The MilkTaxi automatically recognises how much milk or water has already been filled into the tank and shows the current fill quantity constantly on the display."
    },
    {
        name: 'Whole milk supplement with calf milk replacer',
        description: "And if whole milk and CMR are to be mixed? What if your milk has a dry matter value of 12.3 % but you need an overall mix with 13.5 % dry matter? SmartMix recognises how much whole milk has been filled and determines how much water and CMR have to be added to achieve the required milk quantity in the required concentration."
    },
]

const technicalFeatures = [
    { name: 'Working Tank Capacity', description: '100L - 400L' },
    { name: 'Power Supply', description: '230V / 16A or 400V / 16A' },
    { name: 'Pump Capacity', description: 'approx. 40 l/min' },
    { name: 'Heating Power', description: '3 kW, 5 kW, 6 kW or 9.7 kW' },
    { name: 'Agitator Power', description: '250 W' },
]

const splitImageFeatures = [
    { name: 'Dark? No problem', description: 'Thanks to the integrated LED drive light you always see where you are going. So feeding calves in more remote calf hutches in the evening is no problem. In addition, the new top-mounted SpotLight brings light to the dark: the completely flexible gooseneck provides a 360° all-round view at working height.' },
    { name: 'Glove compartment – for small and large aids', description: 'This practical storage box offers space for all the small tools needed in the calf pen. Everything has its place in the practical glove compartment, whether ear tag pliers, thermometer, weight measuring tape, disposable gloves, barn lists or other important items. What is more, the level cover serves as an excellent writing surface. All theses things are quickly at hand in other places because the entire box can be detached from the MilkTaxi and transported very easily.' },
    { name: 'Bucket transport', description: 'The hinged frame, allowing you to transport up to two milk churns, is particularly practical. It can also be used for moving other things such as dry feed or tools. The 8 l colostrum jugs are particularly practical. Inserted in the larger churn with hot water, the milk remains at the perfect temperature before it is fed to the calves.' },
    { name: 'FlushMaster bucket cleaning', description: 'Cleaning feed buckets is time-consuming, as you have to collect the buckets and take them to the washing area. The FlushMaster allows you to clean the buckets using a rotating nozzle in the MilkTaxi, directly at the calf pen. This practical aid can be retrofitted to all MilkTaxi models with a pump.' },
]


export default function Example() {
    return (
        <Layout>
            <div className="bg-white">
                <div className="max-w-7xl mx-auto pt-16 px-4 sm:pt-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <StaticImage
                            src="../../images/neue_CI_HL_Logo_Normal.png"
                            alt="Holm & Laue Logo"
                            className="object-center object-cover mb-8"
                            width={256}
                        />
                        <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase">Mobile calf feeding</h2>
                        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                            MilkTaxi
                        </p>
                        <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                            Convenient bucket feeding: the mobile milk tank enables individual calf feeding through pen recognition, flexible feeding curves, DM calculation.
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="max-w-2xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="grid items-center grid-cols-1 gap-y-16 gap-x-8 lg:grid-cols-2">
                        <div>
                            <div className="border-b border-gray-200 pb-10">
                                <h2 className="font-medium text-gray-500">Bucket feeding made easy</h2>
                                <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Mobile calf feeding – carefully planned down to the last detail
                                </p>
                                <p className="mt-4 text-gray-500">
                                    The MilkTaxi has revolutionised bucket feeding for calves. Whether individually in calf igloos or in groups in the calf barn, the MilkTaxi feeds your calves wherever they are. They can reliably mix milk replacer powder and gently heat or pasteurise whole milk.
                                </p>
                            </div>

                            <dl className="mt-10 space-y-10">
                                {features.map((feature) => (
                                    <div key={feature.name}>
                                        <dt className="text-sm font-medium text-gray-900">{feature.name}</dt>
                                        <dd className="mt-3 text-sm text-gray-500">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>

                        <div>
                            <div className="rounded-lg bg-gray-100 overflow-hidden">
                                <StaticImage
                                    src="../../images/products/milktaxi/MTX-vor-Halle.jpg"
                                    alt="A close up of the MilkTaxi."
                                    className="w-full h-full object-center object-cover"
                                    aspectRatio={1/1}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-4 mt-4 sm:gap-6 sm:mt-6 lg:gap-8 lg:mt-8">
                                <div className="rounded-lg bg-gray-100 overflow-hidden">
                                    <StaticImage
                                        src="../../images/products/milktaxi/Heizen-Heating1.jpg"
                                        alt="A diagram showing the heated base of the MilkTaxi."
                                        className="w-full h-full object-center object-cover"
                                        aspectRatio={1/1}
                                    />
                                </div>
                                <div className="rounded-lg bg-gray-100 overflow-hidden">
                                    <StaticImage
                                        src="../../images/products/milktaxi/Ausdosieren.jpg"
                                        alt="A hand pushing the MilkTaxi by its rounded stainless steel handles."
                                        className="w-full h-full object-center object-cover"
                                        aspectRatio={1/1}
                                    />
                                </div>
                                <div className="rounded-lg bg-gray-100 overflow-hidden">
                                    <StaticImage
                                        src="../../images/products/milktaxi/MTX-strong-in-Winter-4-700x467.jpg"
                                        alt="The wheels of the MilkTaxi being pushed through snow and ice in winter conditions."
                                        className="w-full h-full object-center object-cover"
                                        aspectRatio={1/1}
                                    />
                                </div>
                                <div className="rounded-lg bg-gray-100 overflow-hidden">
                                    <StaticImage
                                        src="../../images/products/milktaxi/SmartMIX.jpg"
                                        alt="A worker pouring milk into the open lid of the SmartMIX."
                                        className="w-full h-full object-center object-cover"
                                        aspectRatio={1/1}
                                    />
                                </div>
                                <div className="rounded-lg bg-gray-100 overflow-hidden">
                                    <StaticImage
                                        src="../../images/products/milktaxi/Smartmixanzeige.jpg"
                                        alt="A close up of the built in display on the MilkTaxi."
                                        className="w-full h-full object-center object-cover"
                                        aspectRatio={1/1}
                                    />
                                </div>
                                <div className="rounded-lg bg-gray-100 overflow-hidden">
                                    <StaticImage
                                        src="../../images/products/milktaxi/Ausdosieren-FB-1-700x467.jpg"
                                        alt="The MilkTaxi spout dispensing milk into a bucket."
                                        className="w-full h-full object-center object-cover"
                                        aspectRatio={1/1}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SplitImageFeatures
                subtitle="Useful Features"
                description="User-friendly display, simple cleaning, the ability to also clean the teat buckets with the MilkTaxi: you will never want to go without your MilkTaxi again."
                features={splitImageFeatures}
            >
                <StaticImage
                    src="../../images/products/milktaxi/DSC_0581-Bearbeitet.jpg"
                    alt="Black leather journal with silver steel disc binding resting on wooden shelf with machined steel pen."
                    className="h-full w-full object-center object-cover lg:h-full lg:w-full"
                    aspectRatio={3/2}
                />
            </SplitImageFeatures>
            <TechnicalSpecs
                heading="MilkTaxi Technical Specifications"
                description="MilkTaxi 100L, MilkTaxi 150L, MilkTaxi 260L, MilkTaxi 400L"
                features={technicalFeatures}
            >
                <StaticImage
                    src="../../images/products/milktaxi/MilchTAxi-Hero-1920x800.jpg"
                    alt="MilkTaxi being pushed"
                    className="w-full h-96 object-center object-cover"
                />
            </TechnicalSpecs>
            <CallToAction
                title="Want to learn more?"
                description="Visit Holm & Laue or contact us directly."
                url="https://www.holm-laue.com/milchtaxi/"
            >
            </CallToAction>
        </Layout>
    )
}
